import React, { useState, ReactNode, useEffect, useCallback } from 'react'
import { Box, Button } from '@mui/material'
import { CheckboxProps } from '@mui/material/Checkbox'
import { FormControlLabelProps } from '@mui/material/FormControlLabel'
import ColoredBox from 'components/ColoredBox'
import { useAppDispatch, useAppSelector } from 'commons/store/hooks'
import {
  fetchCollection,
  fetchElementDataCollections,
  resetElementDataCollection,
  resetElementDataCollections,
  selectCollection,
  selectElementDataCollection,
  selectElementDatas,
} from 'features/ecrf/redux/ecrfSlice'
import TableForm from 'components/Table/TableForm'
import { useParams } from 'react-router-dom'
import ElementTable from "core/helpers/ElementTable";
import { useTranslation } from "react-i18next";

export interface TableFormikProps
  extends Omit<CheckboxProps, 'name' | 'onChange' | 'onBlur' | 'error'> {
  name: string
  label?: ReactNode
  labelPlacement?: FormControlLabelProps['labelPlacement']
  children?: any[]
  element: any;
  data: any;
  setIsOpenQueryPanel: (elementId: string) => void
  isOpenQueryPanel: string | null
  status?: boolean
  permissionOperations?: any
}

const TableFormik = ({
  name,
  label,
  value,
  status,
  element,
  data,
  labelPlacement,
  children,
  setIsOpenQueryPanel,
  isOpenQueryPanel,
  permissionOperations,
  ...props
}: TableFormikProps) => {
  const dispatch = useAppDispatch()
  const collection = useAppSelector(selectCollection)
  const elementDataCollection = useAppSelector(selectElementDataCollection)
  const ed = useAppSelector(selectElementDatas)
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>()
  const [page, setPage] = useState(1)
  const [columns, setColumns] = useState<any[]>([])
  const [collections, setCollections] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  const init = () => {
    createColumns();
  }

  const createColumns = () => {
    setColumns(ElementTable.createColumns(children) || []);
  }

  const fetch = useCallback(async () => {
    setIsLoading(true);

    let rows = [];
    if (data?.id) {
      rows = await dispatch(fetchElementDataCollections({
        id: data?.id,
        params: {'order[createdAt]': 'desc'}
      })).unwrap();
    } else if (ed?.element?.id === name) {
      rows = await dispatch(fetchElementDataCollections({
        id: ed.id,
        params: {'order[createdAt]': 'desc'}
      })).unwrap();
    }

    rows && setCollections({ children, dataId: (data?.id || ed?.id), ecrf: data?.ecrf, rows });

    setIsLoading(false);
  }, [children, data?.id, ed?.id]);

  useEffect(() => {
    init();
    fetch().then();

    /** clear state after unmount */
    return () => {
      dispatch(resetElementDataCollections());
    };
  }, [collection, id, data?.id])

  useEffect(() => {
    fetch().then();
  }, [ed])

  const handleClick = async () => {
    dispatch(resetElementDataCollection());
    dispatch(resetElementDataCollections());

    // let dataId = data?.id || ed?.id || edEdited?.id;
    let dataId = data?.id || ed?.id;

    // @todo always null id on new collection, if no needed current element id
    let colId = element.options?.autosave ? null : name;

    await dispatch(
      fetchCollection({
        id: colId,
        children,
        dataId,
        status,
        ecrfId: id || '',
        elementId: element?.id,
        elementName: `${label}`,
        elementType: element.type,
        options: element.options,
        type: 'add'
      }),
    );
  }

  return (
    <Box pt={3} pb={3}>
      <ColoredBox
        style={{ boxShadow: 'none' }}
        p={1}
        mb={3}
        maxWidth="100%"
        ml="auto"
        mr="auto"
      >
        <TableForm
          collections={collections}
          page={page}
          status={status}
          setPage={setPage}
          columns={columns}
          elementName={`${label}`}
          elementType={element?.type}
          elementOptions={element?.options}
          isLoading={isLoading}
          deleteOption={true}
          duplicateOption={true}
        />
      </ColoredBox>

      <Box textAlign="center">
        {(element?.options?.addRow ?? true) && <Button
            variant="contained"
            color="primary"
            disabled={isLoading || status}
            onClick={handleClick}
        >
          {t('create')} {label}
        </Button>}
      </Box>
    </Box>
  )
}

export default TableFormik
