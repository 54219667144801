import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";

interface State {
  elements: any;
}

const initialState: State = {
  elements: [],
};

export const fetchElements = createAsyncThunk(
    'section/elements',
    async (id: string, thunkAPI) => {
      try {
        const response = await axiosSecureInstance.get<any[]>(
            `/api/sections/${id}/elements`
        );
        return response.data
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
)

export const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    resetElements(state) {
      state.elements = initialState.elements;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchElements.fulfilled, (state, action) => {
      state.elements = action.payload;
    });
  },
});

export const selectElements = (state: RootState) => state.ecrf.section.elements;

export const {
  resetElements,
} = sectionSlice.actions;

export default sectionSlice.reducer;
