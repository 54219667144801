import { roles } from 'commons/auth/roles';
import { permissionOperations } from "commons/auth/permission";

export default class Status {
    /**
     * This method is used to check status and return props for element
     * @param profileRoles
     * @param userPermissionOperations
     * @param ecrfStatus
     * @param status
     * @param mode
     */
    public static controller(
        profileRoles: any,
        userPermissionOperations: any,
        ecrfStatus?: any,
        status?: any,
        mode?: any
    ) {
        if (userPermissionOperations.some(
            (operation: string) => permissionOperations.edit.includes(operation)
        )) {
            return false;
        }

        if (profileRoles.some(
            (role: string) => roles.monitor.includes(role)
        )) {
            return true;
        }

        // ecrf status
        switch (ecrfStatus) {
            case 1:
            case 2:
            case 5:
            case 6:
            case 7: {
                return true;
            }
        }

        // element mode
        switch (mode) {
            case 'block': {
                return true;
            }
        }

        return !!status;
    }

    /**
     * disabled
     */
    public static disabled(profileRoles: any) {
        return !profileRoles.some((role: string) => (
            roles.admin.includes(role) || (
                roles.monitor.includes(role) && !roles.readonly.includes(role)
            )
        ));
    }

    /**
     * statuses
     */
    public static statuses(profileRoles: any, elementStatuses: any) {
        return profileRoles.includes('ROLE_C_RESEARCHER') ? elementStatuses : elementStatuses?.filter(
            (label: any) => label.toLowerCase() !== 'signed'
        )
    }
}
