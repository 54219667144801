import { permissionOperations } from "commons/auth/permission";
import _ from 'lodash';

export default class Permission {
  /**
   * Has access fn
   * @param operations
   * @param type 'ALL' | 'ADD' | 'EDIT' | 'DELETE' | 'HISTORY'
   */
  public static hasAccess(
    operations: any,
    type: 'ALL' | 'ADD' | 'EDIT' | 'DELETE' | 'HISTORY'
  ) {
    switch (type) {
      case "ALL":
        return (operations.some(
          (operation: string) => permissionOperations.all.includes(operation)
        ))
      case "ADD":
        return (operations.some(
          (operation: string) => permissionOperations.add.includes(operation)
        ))
      case "EDIT":
        return (operations.some(
          (operation: string) => permissionOperations.edit.includes(operation)
        ))
      case "DELETE":
        return (operations.some(
          (operation: string) => permissionOperations.delete.includes(operation)
        ))
      case "HISTORY":
        return (operations.some(
          (operation: string) => permissionOperations.history.includes(operation)
        ))
      default:
        return false;
    }
  }

  /**
   * This method is used to check status and return props for element
   * @param userProfile
   * @param userPermission
   * @return any
   */
  public static operations(
    userProfile: any,
    userPermission: any,
  ) {
    const ops: any[] = []
    userPermission?.forEach((permission: any) => {
      if (
        !userProfile?.roles.some((role: string) => permission?.roleNames.includes(role)) &&
        !permission?.userNames.includes(userProfile?.email)
      ) {
        return;
      }

      ops.push(...permission?.operations);
    })

    return _.uniq(ops);
  }
}

