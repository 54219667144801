import { combineReducers } from "@reduxjs/toolkit";
import ecrfSlice from "./ecrfSlice";
import elementSlice from "./elementSlice";
import elementDataSlice from "./elementDataSlice";
import elementDataCollectionSlice from "./elementDataCollectionSlice";
import elementResourceTransfer from "./elementResourceTransferSlice";
import sectionSlice from "./sectionSlice";

export default combineReducers({
  ecrf: ecrfSlice,
  element: elementSlice,
  elementData: elementDataSlice,
  elementDataCollectionSlice: elementDataCollectionSlice,
  elementResourceTransfer: elementResourceTransfer,
  section: sectionSlice,
});
