export const permissionOperations = {
  all: [
    'ADD',
    'CREATE',
    'EDIT',
    'DELETE',
    '*',
  ],
  add: [
    'ADD',
    'CREATE',
    '*',
  ],
  edit: [
    'EDIT',
    '*',
  ],
  delete: [
    'DELETE',
    '*',
  ],
  history: [
    'HISTORY',
    '*',
  ],
};

